import React, {Component, useState} from 'react';
import {Col, Row, Typography} from "antd";
import {Image} from 'antd';
import {Link} from "react-router-dom";

const Img = () => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Image
        preview={{visible: false}}
        width={200}
        src={'https://esg.aeve.ru/img/targets.png'}
        onClick={() => setVisible(true)}
      />
      <div style={{display: 'none'}}>
        <Image.PreviewGroup preview={{visible, onVisibleChange: vis => setVisible(vis)}}>
          <Image src={'https://esg.aeve.ru/img/targets.png'}/>
        </Image.PreviewGroup>
      </div>
    </>
  );
};

class WhatWeDid extends Component <any, any> {
  constructor(props: any) {
    super(props);
  }
  render() {
    return <div className="whatWeDid">
      <Row>
        <Col span={24}>
          <Typography.Title level={3}>{this.props.name}</Typography.Title>
        </Col>
      </Row>
      <Typography.Title level={3}>
        Главный ESG принцип осуществление деятельности для достижения целей долгосрочного устойчивого развития.
      </Typography.Title>
      <div className={'targets-wrapper'}>
        <div>
          <Image preview={false} width={100} src={'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/UN_emblem_blue.svg/512px-UN_emblem_blue.svg.png'}/>
        </div>
        <div style={{fontSize: '85px'}}>
          ЦЕЛИ
        </div>
        <div style={{fontSize: '35px'}}>
          в области
        </div>
        <div style={{fontSize: '35px', color: '#01AAF0'}}>
          Устойчивого<br/>
          РАЗВИТИЯ
        </div>
      </div>
      <div style={{display: 'grid', justifyContent: 'center', gridTemplateColumns: '1fr'}}>
        <div className={"targets"}></div>
      </div>
      <div>
        <Link to="/Partnerships">Примеры реализации ESG проектов</Link>
      </div>
    </div>;
  }
}

export default WhatWeDid;
