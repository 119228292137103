import React, { Component } from 'react';

interface Circle {
  id: number;
  cx: number;
  cy: number;
  radius: number;
  isHovered: boolean;
}

class NeonTriangle extends Component<{}, { circles: Circle[] }> {
  constructor(props: {}) {
    super(props);
    this.state = {
      circles: [
        { id: 1, cx: 100, cy: 2, radius: 2, isHovered: false },
        { id: 2, cx: 64.5, cy: 68, radius: 2, isHovered: false },
        { id: 3, cx: 135.5, cy: 68, radius: 2, isHovered: false },
      ],
    };
  }

  handleMouseEnter = (circleId: number) => {
    this.setState((prevState) => ({
      circles: prevState.circles.map((circle) =>
        circle.id === circleId ? { ...circle, radius: 4.5, isHovered: true } : circle
      ),
    }));
  };

  handleMouseLeave = (circleId: number) => {
    this.setState((prevState) => ({
      circles: prevState.circles.map((circle) =>
        circle.id === circleId ? { ...circle, radius: 2, isHovered: false } : circle
      ),
    }));
  };

  render() {
    const sinusoidPaths = this.state.circles.map((circle) => (
      this.state.circles
        .filter((otherCircle) => otherCircle.id !== circle.id)
        .map((otherCircle) => {
          const dx = otherCircle.cx - circle.cx;
          const dy = otherCircle.cy - circle.cy;
          const length = Math.sqrt(dx * dx + dy * dy);
          const angle = Math.atan2(dy, dx);
          const x1 = circle.cx + (circle.radius + 2) * Math.cos(angle);
          const y1 = circle.cy + (circle.radius + 2) * Math.sin(angle);
          const x2 = otherCircle.cx - (otherCircle.radius + 2) * Math.cos(angle);
          const y2 = otherCircle.cy - (otherCircle.radius + 2) * Math.sin(angle);

          // Генерация синусоидальной кривой
          const frequency = 2; // Частота синусоиды
          const amplitude = 5; // Амплитуда синусоиды
          const numPoints = 100; // Количество точек на кривой
          const pathData = [`M ${x1} ${y1}`];
          for (let i = 0; i < numPoints; i++) {
            const t = i / (numPoints - 1);
            const x = x1 + (x2 - x1) * t;
            const y = y1 + (y2 - y1) * t + amplitude * Math.sin(2 * Math.PI * frequency * t);
            pathData.push(`L ${x} ${y}`);
          }

          return (
            <path
              key={`${circle.id}-${otherCircle.id}`}
              d={pathData.join(" ")}
              stroke={circle.isHovered ? 'lime' : 'lime'}
              strokeWidth={circle.isHovered ? '0.5' : '0.1'} // Обновлено значение толщины
              fill="none"
              className={`neon-line ${circle.isHovered ? 'hovered' : ''}`} // Добавлены классы для стилей
            />
          );
        })
    ));

    return (
      <div className="neon-container"> {/* Добавлен контейнер для стилей */}
        <svg
          className="arc"
          id="arc"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 100"
          style={{ width: '100%', height: '100%' }}
        >
          <defs>
            <filter id="neon-glow" x="-50%" y="-50%" width="200%" height="200%">
              {/* Фильтр свечения */}
              <feGaussianBlur in="SourceAlpha" stdDeviation="10" result="blur" />
              <feColorMatrix in="blur" mode="matrix" values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 0" result="invert" />
              <feOffset in="blur" dx="3" dy="3" result="offsetBlur" />
              <feComposite in="offsetBlur" in2="invert" operator="in" result="inverse" />
              <feFlood floodColor="blue" floodOpacity="1" result="color" />
              <feComposite in="color" in2="inverse" operator="in" result="coloredBlur" />
              <feMerge>
                <feMergeNode in="coloredBlur" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
            <filter id="neon-glow-hover" x="-50%" y="-50%" width="200%" height="200%">
              <feMerge>
                <feGaussianBlur in="SourceAlpha" stdDeviation="10" result="blur" />
                <feMergeNode in="coloredBlur" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>

          {/* Зеленые линии (под черными кружками) */}
          {this.state.circles.map((circle) => (
            this.state.circles
              .filter((otherCircle) => otherCircle.id !== circle.id)
              .map((otherCircle) => {
                const dx = otherCircle.cx - circle.cx;
                const dy = otherCircle.cy - circle.cy;
                const length = Math.sqrt(dx * dx + dy * dy);
                const angle = Math.atan2(dy, dx);
                const x1 = circle.cx + (circle.radius + 2) * Math.cos(angle);
                const y1 = circle.cy + (circle.radius + 2) * Math.sin(angle);
                const x2 = otherCircle.cx - (otherCircle.radius + 2) * Math.cos(angle);
                const y2 = otherCircle.cy - (otherCircle.radius + 2) * Math.sin(angle);

                return (
                  <line
                    key={`${circle.id}-${otherCircle.id}`}
                    x1={x1}
                    y1={y1}
                    x2={x2}
                    y2={y2}
                    stroke={circle.isHovered ? 'lime' : 'lime'}
                    strokeWidth={circle.isHovered ? '0.5' : '0.1'} // Обновлено значение толщины
                  />
                );
              })
          ))}

          {/* Орбиты с неоновым свечением */}
          {this.state.circles.map((circle) => (
            <circle
              key={`orbit-${circle.id}`}
              cx={circle.cx}
              cy={circle.cy}
              r={circle.radius + 1} // Радиус орбиты чуть больше радиуса круга
              strokeWidth={circle.isHovered ? '2' : '1'}
              fill="none"
              stroke={circle.isHovered ? 'lime' : 'lime'}
              vectorEffect="non-scaling-stroke"
              filter={`url(#${circle.isHovered ? 'neon-glow-hover' : 'neon-glow'})`} // Применение фильтра при наведении
            />
          ))}

          {this.state.circles.map((circle) => (
            <React.Fragment key={circle.id}>
              {/* Черные кружки */}
              <circle
                cx={circle.cx}
                cy={circle.cy}
                r={circle.radius}
                strokeWidth="0"
                fill="black"
                vectorEffect="non-scaling-stroke"
                onMouseEnter={() => this.handleMouseEnter(circle.id)}
                onMouseLeave={() => this.handleMouseLeave(circle.id)}
                className={`neon-circle ${circle.isHovered ? 'hovered' : ''}`} // Добавлены классы для стилей
              />
            </React.Fragment>
          ))}

          {/* Зеленое свечение */}
          <polygon points="100,2 64.5,68 135.5,68" fill="none" stroke="none" strokeWidth="2" filter="url(#neon-glow)" vectorEffect="non-scaling-stroke" />
        </svg>
      </div>
    );
  }
}

export default NeonTriangle;
