import {Layout, Menu, Typography} from 'antd';
import React, { Component, useState } from 'react';
import {Link} from "react-router-dom";
import { Document, Page } from 'react-pdf';

const PdfView = (file: any) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

const onDocumentLoadSuccess = (numPages: any) => {
  setNumPages(numPages);
}

  return (
    <div>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p>
    </div>
  );
}


const { Sider } = Layout;
const { Content } = Layout;
const { Item } = Menu;

class Partnerships extends Component <any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      numPages: null,
      pageNumber: 1
    };
  }

  render() {
    const { pageNumber, numPages } = this.state;

    return <Layout>
      <Content>
          <PdfView file={"http://localhost:3000/pdf-test.pdf"}/>
      </Content>
      <Sider>
        <Menu theme="dark" mode="vertical" defaultSelectedKeys={['1']}>
          <Item key="1">Проект 1</Item>
          <Item key="2">Проект 2</Item>
          <Item key="3">Проект 3</Item>
          <Item key="4">Проект 4</Item>
          <Item key="5">Проект 5</Item>
          <Item key="6">Проект 6</Item>
          <Item key="7">Проект 7</Item>
          <Item key="8">Проект 8</Item>
        </Menu>
      </Sider>
    </Layout>;
  }
}

export default Partnerships;
