import React, { Component } from 'react';



class Partners extends Component <any, any> {
  constructor(props: any) {
    super(props);
  }
  render() {
    return <h1>{this.props.name}</h1>;
  }
}

export default Partners;
