import { Typography, Row, Col } from 'antd';
import React, { Component } from 'react';

class About extends Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div className="about-container">
        <Row gutter={[16, 16]}>
          <Col xs={24} md={24}>
            <Typography.Title level={3}>О нас:</Typography.Title>
            <Typography.Paragraph>
              Устойчивое развитие – это способность отвечать на любые вызовы в условиях
              непрерывных кризисов, т.е. обеспечивать воспроизводство всех видов капитала:
              финансового, производственного, интеллектуального, информационного, природного,
              человеческого, социального, в интересах нынешнего и будущих поколений.
            </Typography.Paragraph>
            <Typography.Paragraph>
              МЫ реализует бизнес-модель ESG, руководствуясь в своей деятельности принципами
              социальной и экологической ответственности, опираясь на лучшую мировую практику
              корпоративного управления, и мотивирует клиентов и партнеров внедрять Цели устойчивого
              развития ООН в свои бизнес-процессы.
            </Typography.Paragraph>
          </Col>
          <Col xs={24} md={24}>
            <Typography.Paragraph>
              В основе ESG-стратегии лежат структурные перемены и диджитализация продуктов для
              населения и сервисов для бизнеса на фоне устойчивого роста ключевых финансовых
              показателей. МЫ строит прочные отношения со всеми заинтересованными сторонами по
              актуальным направлениям устойчивого развития, такими как ответственное потребление и
              производство, устойчивые города и населенные пункты, партнерство в интересах устойчивого
              развития, содействие качественному образованию и развитию финансовой грамотности
              населения, экономический рост и благополучие.
            </Typography.Paragraph>
          </Col>
        </Row>
      </div>
    );
  }
}

export default About;
