import React, {Component} from 'react';
import {Card, Row, Col, Typography} from 'antd';

const { Meta } = Card;

const services = [
  {
    title: "ESG corporate strategy development / Разработка стратегий ESG трансформации бизнеса",
    description: "Мы помогаем компаниям разрабатывать стратегии ESG для трансформации их бизнеса."
  },
  {
    title: "Consulting and support of companies in revision and development of the corporate politics and decisions / Консультирование и поддержка компаний по подготовке необходимых политик и решений",
    description: "Мы консультируем и поддерживаем компании в разработке корпоративных политик и решений."
  },
  {
    title: "Preparation of Sustainable Development Report for a company/ Подготовка отчета об устойчивом развитии компании",
    description: "Мы готовим отчеты об устойчивом развитии компаний, помогая им демонстрировать свои устойчивые практики."
  },
  {
    title: "Companies’ assistance in receiving ESG-rating/ Поддержка компаний в получении ESG-рейтинга",
    description: "Мы помогаем компаниям получить рейтинг по ESG, что способствует привлечению инвестиций."
  },
  {
    title: "Integration of ESG factors into investment strategy (for financial companies) / Интеграция факторов ESG в инвестиционную стратегию (для финансовых компаний)",
    description: "Мы помогаем финансовым компаниям интегрировать факторы ESG в свои инвестиционные стратегии."
  },
  {
    title: "Conducting an environmental audit/ Проведения экологического аудита",
    description: "Мы проводим экологические аудиты, оценивая воздействие компаний на окружающую среду."
  },
  {
    title: "Conducting an ESG audit in order to prepare for the placement of «green» bonds/ Проведение ESG аудита с целью подготовки к размещению «зеленых» облигаций",
    description: "Мы проводим ESG аудиты, готовя компании к размещению «зеленых» облигаций."
  },
  {
    title: "Executive coaching and education trainings for the staff of companies that are undergoing ESG transformation / Коучинг руководителей и проведение обучающих семинаров для персонала компаний, трансформирующих свой бизнес с учетом ESG факторов",
    description: "Мы предоставляем коучинг и обучение для персонала компаний, преобразующих свой бизнес с учетом ESG."
  },
  {
    title: "Communication audit and development of recommendations about the finalization of communication strategy, reflecting ESG-factors / Коммуникационный аудит и подготовка рекомендаций по доработке коммуникационной стратегии компаний с учетом факторов ESG",
    description: "Мы проводим коммуникационные аудиты и разрабатываем рекомендации по стратегии коммуникаций с учетом ESG."
  },
  {
    title: "Estimation of carbon footprint / Оценка углеродного следа",
    description: "Мы оцениваем углеродный след компаний, помогая им улучшить свои показатели в сфере устойчивости."
  }
];

class WhatWeDo extends Component <any, any> {
  constructor(props: any) {
    super(props);
  }
  render() {
    return (
      <div className="services-container">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Typography.Title level={3}>{this.props.name}</Typography.Title>
          </Col>
          {services.map((service, index) => (
            <Col xs={24} sm={24} md={24} lg={12} xl={8} key={index}>
              <div className="service-card"> {/* Добавляем класс service-card */}
                <Card>
                  <Meta title={service.title} description={service.description}/>
                </Card>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
};

export default WhatWeDo;
