import React, { Component } from 'react';
import {Card, Row, Col, Typography} from 'antd';

class News extends Component <any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const newsContent = [
      'Россия несмотря на санкции не будет пересматривать планы по развитию углеродного рынка, заверил спецпредставитель президента Руслан Эдельгериев. В корпоративном секторе ожидают, что фокус сместится в сторону социального сегмента ESG.',
      'Владимир Путин подписал закон о сахалинском эксперименте - Сахалин к 2026 году должен стать первым регионом России, достигшим углеродной нейтральности. Там же будет обкатываться система торговли углеродными единицами.',
      'Большая четвёрка аудиторов (EY, KPMG, Deloitte, PwC) решила выделить российские офисы из глобальной сети. Другими словами, практики останутся, в том числе по ESG, но вывеска сменится. Лишний раз это подтвердили собеседницы @esgworld в EY Russia и PwC.',
      'По этому же мотиву из России ушла платформа сертификации "зелёной" энергии I-REC, которую мы не раз упоминали в своих материалах, а влиятельная организация по учёту выбросов парниковых газов CDP решила разорвать отношения с местными компаниями. Климат вне политики? Отнюдь.',
      'Meta разрешила публиковать призывы к насилию в отношении российских военных и руководства России в контексте Украины. Теперь это считается политическими заявлениями. Между тем борьба с hate speech - один из элементов ESG-стратегии Meta (наш обзор - вот здесь).',
      'Украинский кризис заставил ESG-фонды задуматься, можно ли вкладываться в компании с хорошим ESG-профилем, но базирующиеся в стране - "серийной нарушительнице прав человека" (формулировка Bloomberg). "Мы сделали большую ошибку, не сочтя вторжение в Крым (sic) тем, чем оно являлось", - сокрушается один из собеседников Reuters.',
      'Евросоюз решил сократить зависимость от российского газа на две трети за год. Уголь больше не пария: странам типа Польши, чей энергобаланс базируется на угле, возможно, разрешат жечь его дальше, а потом сразу перейти на ВИЭ, минуя газ.',
      'И действительно, неизбежным следствием снижения доли российского газа в ЕС станет рост потребления угля - самого "грязного" энергоресурса, прогнозирует S&P.',
      'Ещё одно свидетельство отката от экологических целей из-за политики: премьер Британии Борис Джонсон предложил возобновить добычу газа методом фрекинга, чтобы снизить зависимость от России. Метод, мягко говоря, вызывает обеспокоенность у экологов.',
      'Никель, один из ключевых металлов энергоперехода, беспрецедентно подорожал на опасениях сбоя цепочки поставок из-за антироссийских санкций. На пике тонна металла стоила $100+ тыс. Электромобили могут подорожать - Tesla, например, уже подняла ценник.',
    ];

    return (
      <div className="news-container">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Typography.Title level={3}>{this.props.name}</Typography.Title>
          </Col>
          {newsContent.map((content, index) => (
            <Col span={24} key={index}>
              <Card>{content}</Card>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}

export default News;
