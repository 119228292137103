import React, { Component } from 'react';
import { Layout, Menu, Button } from 'antd';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import AppContent from "./components/AppContent";
import About from "./components/About";
import WhatWeDo from "./components/WhatWeDo";
import WhatWeDid from "./components/WhatWeDid";
import Partners from "./components/Partners";
import News from "./components/News";
import Team from "./components/Team";
import Contacts from "./components/Contacts";
import Partnerships from './components/Partnerships';
import {
  HomeOutlined,
  InfoCircleOutlined,
  AppstoreAddOutlined,
  ProjectOutlined,
  TeamOutlined,
  SolutionOutlined,
  FileSearchOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  FundProjectionScreenOutlined,
} from '@ant-design/icons';
import { Footer } from 'antd/lib/layout/layout';

const { Header, Content, Sider } = Layout;
const { Item } = Menu;

class App extends Component<any, { collapsed: boolean, isMobile: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = {
      collapsed: false,
      isMobile: window.innerWidth < 768, // Определяем, является ли экран мобильным при загрузке
    };
  }

  toggleMenu = () => {
    this.setState((prevState) => ({ collapsed: !prevState.collapsed }));
  };

  handleWindowResize = () => {
    // Проверяем изменение размера окна и обновляем состояние для определения, мобильное ли устройство
    this.setState({ isMobile: window.innerWidth < 768 });
  };

  componentDidMount() {
    // Добавляем обработчик изменения размера окна
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    // Удаляем обработчик изменения размера окна при размонтировании компонента
    window.removeEventListener('resize', this.handleWindowResize);
  }

  render() {
    const { collapsed, isMobile } = this.state;

    return (
      <BrowserRouter>
        <Layout>
          <Header>
            <div className={'logo'}>
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 150 150">
                <circle cx="75" cy="75" r="60" fill="#4CAF50"></circle>
                <text x="22" y="67%" font-family="Arial" font-size="70" fill="#ffffff">E</text>
                <text x="52" y="67%" font-family="Arial" font-size="70" fill="#ffffff">S</text>
                <text x="75" y="67%" font-family="Arial" font-size="70" fill="#ffffff">G</text>
              </svg>
            </div>
              <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
                <Menu.Item key="1" icon={<HomeOutlined />}>
                  <Link to="/"></Link>
                </Menu.Item>
                <Menu.Item key="2" icon={<InfoCircleOutlined />}>
                  <Link to="/About"></Link>
                </Menu.Item>
                <Menu.Item key="3" icon={<AppstoreAddOutlined />}>
                  <Link to="/WhatWeDo"></Link>
                </Menu.Item>
                <Menu.Item key="4" icon={<FundProjectionScreenOutlined />}>
                  <Link to="/WhatWeDid"></Link>
                </Menu.Item>
                <Menu.Item key="5" icon={<ProjectOutlined />}>
                  <Link to="/Partners"></Link>
                </Menu.Item>
                <Menu.Item key="6" icon={<SolutionOutlined />}>
                  <Link to="/News"></Link>
                </Menu.Item>
                <Menu.Item key="7" icon={<TeamOutlined />}>
                  <Link to="/Team"></Link>
                </Menu.Item>
                <Menu.Item key="7" icon={<FileSearchOutlined />}>
                  <Link to="/Contacts"></Link>
                </Menu.Item>
              </Menu>
          </Header>
          <Layout>
            <Content className={'scrollable-content'} style={{padding: `0 ${isMobile ? '0' : '20%'}`}}>

              <Routes>
                <Route path='/' element={<AppContent name="Главная"/>}/>
                <Route path='/About' element={<About name="О нас"/>}/>
                <Route path='/WhatWeDo' element={<WhatWeDo name="Услуги"/>}/>
                <Route path='/WhatWeDid' element={<WhatWeDid name="Проекты"/>}/>
                <Route path='/Partners' element={<Partners name="Партнеры"/>}/>
                <Route path='/News' element={<News name="Новости"/>}/>
                <Route path='/Team' element={<Team name="Команда"/>}/>
                <Route path='/Contacts' element={<Contacts name="Контакты"/>}/>
                <Route path='/Partnerships' element={<Partnerships/>}/>
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </BrowserRouter>
    );
  }
}

export default App;
