import React, { Component } from 'react';
import NeonTriangle from './NeonTriangle';

class AppContent extends Component <any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return <NeonTriangle/>;
  }
}

export default AppContent;
